.thBuscaContratos {
    color: #33348F;
    background-color: #fff; 
    padding: 5px; 
    border: 1px solid #ddd;
    font-weight: 600; 
    font-size: 11px;
}

.tdBuscaContratos {
    padding: 5px; 
    background-color: #00000000; 
    border: 1px solid #ddd;
    font-size: 10px; 
    color: black; 
    text-align: left
}

.linkBuscaContratos {
    text-decoration: none;
}