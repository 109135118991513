.caixaSaldo {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 25%;
    transform: translateX(-50%);
    display: flex; 
    align-items: center; 
    justify-content: center;
    margin-top: 1vh;
    margin-left: auto; 
    margin-right: auto; 
    width: 22vw; 
    height: 6vw;
    border: 1px solid #ddd; 
    border-radius: 8px;
    z-index: 10;
    background-color: #fff;
}

.internoSaldo {
    font-size: 1.5rem;
    text-align: center;
}

.regionalSaldo {
    font-size: 1.3rem;
    text-align: center;
}

.scroll-tabela {
    overflow-x: visible;
}

.tabelaNovoDfd {
    margin-top: "2vh";
    margin-bottom: "2vh";
    padding: 16px; 
    border-collapse: collapse; 
    text-align: center; 
    border-radius: 10px;
}

.thNovoDfd {
    color: #33348F;
    background-color: #fff; 
    padding: 5px; 
    border: 1px solid #ddd;
    font-weight: 600;
    font-size: 11;
}

.tdNovoDfd {
    padding: 5px; 
    border: 1px solid #ddd;
    font-size: 11;
    text-align: left;
}