/* CplDal.css */

table {
    width: 100%;
    border-collapse: collapse; /* Remove espaço entre as bordas */
    font-size: 13px; /* Modifica o tamanho da fonte para todas as tabelas */
    table-layout: fixed; /* Garante que a largura das colunas seja fixa */
}

table, th, td {
    border: 1px solid #ccc; /* Adiciona borda cinza clara */
}

th, td {
    padding: 8px; /* Adiciona espaçamento interno */
    text-align: left; /* Alinha o texto à esquerda */
    overflow: hidden; /* Previne o conteúdo de sair da célula */
}

th {
    background-color: #f2f2f2; /* Cor de fundo para o cabeçalho da tabela */
}

tr:nth-child(even) {
    background-color: #f9f9f9; /* Cor de fundo para linhas pares */
}

tr:hover {
    background-color: #e8e8e8; /* Cor de fundo ao passar o mouse */
}

.btn-salvar, .btn-mostrar-ocultar {
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: #09792d; /* Verde para salvar, azul para mostrar/ocultar */
    color: #FFFF;
    padding: 5px;
}

.btn-mostrar-ocultar {
    background-color: rgb(51, 52, 143); /* Específico para o botão mostrar/ocultar */
}

.titulo-com-botao {
    display: flex;
    align-items: center;
}

.titulo-com-botao h3, .titulo-com-botao button { /* Modificado para h3 conforme o componente JSX */
    margin-right: 10px; /* Espaço entre o título e o botão */
}

.input-objeto-descricao, .input-outros {
    width: 100%; /* Ocupa toda a largura disponível na célula */
    border: none; /* Remove a borda padrão */
    background: none; /* Remove o fundo padrão */
    padding: 0; /* Remove o padding padrão */
}

.input-objeto-descricao {
    width: 100%; /* Ajusta a largura para preencher a célula */
    min-height: 30px; /* Altura mínima */
    height: auto; /* Altura se ajusta ao conteúdo */
    overflow-y: hidden; /* Esconde a barra de rolagem */
    resize: none; /* Impede redimensionamento */
    border: none;
    background-color: transparent;
    padding: 8px; /* Para manter consistência com o padding das células */
    font-size: 11px; /* Para manter consistência com o tamanho da fonte da tabela */
    color: inherit; /* Para herdar a cor do texto */
}

/* Define larguras específicas para as colunas */
th:nth-child(1), td:nth-child(1) { width: 20%; }
th:nth-child(2), td:nth-child(2) { width: 30%; }
