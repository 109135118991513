.containerContrato {
    display: flex;
    gap: 2vw; 
    align-items: center; 
    margin-bottom: 2vh;
}

.inputGroupContrato {
    display: flex;
    flex-direction: column; 
    margin-right: 2vw; 
}

.labelsContrato {
    font-weight: 600;
    font-size: 15px;
    margin-top: 2vh;
}

.inputsContrato {
    border: 1px solid #00000029;
    border-radius: 5px;
    min-height: 18px;
    font-size: 15px;
    margin-top: 1vh;
}
