@media print {
    .nav {
        display: none;
    }
}

.caixa1 {
    width: 14vw;
    border-radius: 8%;
    padding-top: 3vh;
    padding-bottom: 2vh;
    display: none;
    position: absolute;
    z-index: 1000 !important;
}

.caixao {
    text-align: left;
    background: #F9F9F9;
    width: 22vw;
    border-radius: 12px;
    padding-bottom: 2vh;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown:hover .caixa1 {
    display: block;
}