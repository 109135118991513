@media print {
    body {
        -webkit-print-color-adjust: exact;
        forced-color-adjust: exact;
        print-color-adjust: exact;
    }
    thead { display: table-row-group; }
}

.caixa {
    border: 1px solid black;
    width: 96vw;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4vh;
}

.headerCaixa {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    background-color: #d5ccac;
    font-size: 16px;
    text-align: center;
    padding: 5px 0 5px 0;
    font-weight: 600;
}

.header1 {
    border-bottom: 1px solid black;
    padding: 5px 0 5px 5px;
}

.headerSemCor {
    border-bottom: 1px solid black;
    font-size: 15px;
    text-align: center;
    padding: 5px 0 5px 0;
    font-weight: 600;
}

.divLeft {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    padding: 5px 0 5px 5px;
    width: 50%;
    display: inline-grid;
}

.divRight {
    border-bottom: 1px solid black;
    padding: 5px 0 5px 5px;
    width: 50%;
    display: inline-grid;
}

.tableDfd {
    width: 96vw; 
    margin-top: 2vh; 
    margin-bottom: 2vh;
    margin-left: auto; 
    margin-right: auto;
    padding: 16px; 
    border-collapse: collapse; 
    text-align: center; 
    border-radius: 10px;
    table-layout: fixed
}

.thDfd {
    color: #33348F;
    background-color: #fff; 
    padding: 5px; 
    border: 1px solid #ddd;
    font-weight: 600; 
    font-size: 11px;
}

.thDfdGeral {
    color: #33348F;
    background-color: #fff; 
    padding: 5px; 
    border: 1px solid #ddd;
    font-weight: 600; 
    font-size: 9px;
}

.tdDfd {
    padding: 5px; 
    background-color: #00000000; 
    border: 1px solid #ddd;
    font-size: 11px; 
    color: black; 
    text-align: left
}

.tdDfdGeral {
    padding: 5px; 
    background-color: #00000000; 
    border: 1px solid #ddd;
    font-size: 9px; 
    color: black; 
    text-align: left
}