/* Estilos gerais do modal */
.modalPagamentos {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 8px;
    border: 1px solid grey;
    width: 96vw;
    max-height: 90vh; /* Limita a altura total do modal */
    display: flex;
    flex-direction: column; /* Empilha os elementos verticalmente */
    overflow: hidden; /* Garante que o scroll interno respeite os limites */
}

/* Botão de fechar no modal */
.modalPagamentos-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

/* Conteúdo do modal com rolagem */
.modalPagamentos-content {
    flex: 1; /* Permite que o conteúdo ocupe o espaço restante */
    overflow-y: auto; /* Adiciona rolagem vertical ao conteúdo interno */
    padding-right: 1rem; /* Espaçamento para evitar sobreposição com a barra de rolagem */
}
