@media print {
    body {
      -webkit-print-color-adjust: exact;
      forced-color-adjust: exact;
      print-color-adjust: exact;
    }
  }

.caixa {
  border: 1px solid black; 
  width: 96vw; 
  font-size: 12px;
  margin-left: auto; 
  margin-right: auto; 
  margin-bottom: 4vh;
}

.headerCaixa {
  border-bottom: 1px solid black; 
  border-right: 1px solid black; 
  border-top: 1px solid black; 
  background-color: #d5ccac; 
  font-size: 16px;
  text-align: center; 
  padding: 5px 0 5px 0; 
  font-weight: 600;
}

.header1 {
  border-bottom: 1px solid black;
  padding: 5px 0 5px 5px;
}

.headerSemCor {
  border-bottom: 1px solid black; 
  font-size: 15px;
  text-align: center; 
  padding: 5px 0 5px 0; 
  font-weight: 600;
}

.divLeft {
  border-bottom: 1px solid black; 
  border-right: 1px solid black;
  padding: 5px 0 5px 5px; 
  width: 50%; 
  display: inline-grid;
}
.divRight {
  border-bottom: 1px solid black; 
  padding: 5px 0 5px 5px; 
  width: 50%; 
  display: inline-grid;
}

.tableDed {
  width: 94vw; 
  margin-top: 2vh; 
  margin-bottom: 2vh;
  margin-left: auto; 
  margin-right: auto;
  padding: 16px; 
  border-collapse: collapse; 
  text-align: center; 
  border-radius: 10px;
  table-layout: fixed;
}
