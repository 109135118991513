.headerDed {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.principal {
    border-bottom: 1px solid #00000029; 
    width: 50vw;
    margin: auto; 
    margin-top: 2vh; 
    margin-bottom: 2vh; 
    font-size: 16px;
    text-align: left; 
    color: #000000; 
    display: inline-block;
}

.direita {
    float: right; 
    position: relative; 
    top: -3px;
}

.quantidade {
    margin-left: 0.5vw; 
    margin-right: 0.5vw; 
    width: 4vw;
    border-radius: 5px; 
    background: #00000000; 
    text-align: center;
}

.menos {
    width: 3vw;
    background-color: #E90303;
    color: #FFFF;
    border: none; 
    border-radius: 5px;
}

.mais {
    width: 3vw; 
    margin-right: 0.5vw;
    background-color: #42A908; 
    color: #FFFF;
    border: none; 
    border-radius: 5px;
}