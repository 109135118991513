.login {
  background: url('./assets/fundo.png') no-repeat center center fixed;  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.App {
  text-align: center;
}

